import $ from "jquery";

$(function() {
  // hamburger menu
  const $window = $(window);
  const $html = $('html');
  const $body = $('body');
  const menuID = 'mainnav';
  const menuExpandedClass = 'menu-open';
  const $button = $('[aria-controls="' + menuID + '"]');

  function toggleMenu() {
    const isOpen = !$button.is('[aria-expanded="true"]');
    const $menu = $('#' + menuID);

    if( isOpen ) {
      let scrollPosition = $window.scrollTop();

      $body.css('top', -scrollPosition + 'px').attr('data-scroll', scrollPosition);
      $html.addClass(menuExpandedClass);
    } else {
      let scrollPosition = parseInt( $body.attr('data-scroll') );
      $html.removeClass(menuExpandedClass);
      $('body,html').scrollTop(scrollPosition);
    }

    $button.toggleClass('is-active', isOpen).attr({
      'aria-expanded' : isOpen
    });

    /*
     * Allow for some animation to happen before setting display:none; on the menu
     * The delay is based on the css transition duration of .drawer
     */
    const delay = isOpen ? 0 : 250;
    setTimeout(function() {
      $menu.toggleClass('is-active');
      $menu.find('a').first().focus();
    }, delay);

    /*
     * This allows the animation to happen correctly.
     * It ensures the drawer CSS transform transition begins after drawerOpenClass has been set.
     */
    setTimeout(function() {
      $menu.attr('aria-hidden', !isOpen);
    }, 25);

    // After closing the menu, refocus the trigger button
    if (!isOpen) {
      $button.focus();
    }
  }


  $button.on('click', toggleMenu);

  // hamburger submenu
  const $subMenuTrigger = $('.menu-item-has-children > a');

  $subMenuTrigger.on('click', function(e) {
    e.preventDefault();

    const $t = $(this);

    $t.toggleClass('is-active').next().toggle(150);
  });
});

