import Cookies from 'js-cookie';
import $ from "jquery";

$(function () {
  var cookieName = 'cookie-notice-consent';
  var className = 'cookie-notice--active';

  if( Cookies.get(cookieName) ) return false;

  $('body').addClass(className);

  $('.js--cookie-notice-close').on('click', function () {
    $('body').removeClass(className);
    // Keep the notification hidden for 365 days
    Cookies.set(cookieName, Math.round((new Date()).getTime() / 1000), { expires: 365 });
  });

});
