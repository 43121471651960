import $ from "jquery";
const $body = $('body');

$(function () {
  $body
    .on('mousedown', function () {
      $body.addClass('using-mouse');
    })
    .on('keydown', function () {
      $body.removeClass('using-mouse');
    });
});
