import $ from "jquery";
const $accordions = $(".accordion__item");

$("body")
  .unbind("click") // prevent bubbling from admin script.
  .on("click", ".accordion__item button", function() {
    let $item = $(this).parents(".accordion__item");
    let $contents = $item.find(".accordion__contents");

    $item.toggleClass("is--active");
    $contents.stop().slideToggle(150);
});

