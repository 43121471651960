import $ from "jquery";

// form validation
$(".form-contact").on("submit", function(e) {
  let $fields = $(this).find(".field");
  let errorClass = "is-error";

  $.each($fields, function() {
    let $t = $(this).removeClass(errorClass);

    if (!$.trim( $t.val() ).length) {
      e.preventDefault();
      $t.addClass(errorClass);
    }
  });
});

