require("../sass/main.scss");

// require("./css-vars-poly");
require("./main-menu");
require("./share-buttons");
require("./contact-form");
require("./wp-blocks-custom/accordion");
require("./keyboard-focus");
require("./cookie-notice");

const ClipboardJS = require("./plugins/clipboard.min.js");
new ClipboardJS(".js-copy-link");
